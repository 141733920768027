.header {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0 40px;
  width: 100%;
  height: 80px;
  background-color: #fff;
  &__logo {
    position: relative;
    float: left;
    margin: 18px 0 0 0;
    width: 205px;
    height: 42px;
    overflow: hidden;
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url('assets/logo-header.png');
    }
  }
  &__main-nav {
    float: right;
    &__list {
      float: right;
      margin: 0 -5px 0 0;
      padding: 0;
      list-style: none;
    }
    &__list-item {
      float: left;
      padding: 20px 0 20px 20px;
      &:first-child {
        padding-left: 0;
      }
    }
    &__list-link {
      display: block;
      margin: 0;
      padding: 6px 15px 10px 15px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 24px;
      text-decoration: none;
      color: #545454;
      &:hover,
      &.active {
        text-decoration: underline;
        color: #414141;
      }
    }
    &__toggle {
      display: none;
      position: fixed;
      z-index: 4;
      top: 18px;
      right: 38px;
      margin: 0;
      padding: 0;
      width: 42px;
      height: 42px;
      border: none;
      background: none;
      cursor: pointer;
      transition: .25s ease-in-out;
      box-sizing: border-box;
      span {
        display: block;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        -khtml-border-radius: 3px;
      }
      .burger {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: block;
        span {
          position: relative;
          width: 36px;
          height: 6px;
          top: 0;
          left: 3px;
          margin: 6px 0;
          background-color: #545454;
          &:nth-child(1){
            transition-delay: .5s;
          }
          &:nth-child(2){
            transition-delay: .625s;
          }
          &:nth-child(3){
            transition-delay: .75s;
          }
        }
      }
      .cross {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: rotate(45deg);
        background: none;
        span {
          position: absolute;
          background-color: #fff;
          &:nth-child(1){
            top: 18px;
            left: 0;
            width: 42px;
            height: 0;
            transition-delay: 0s;
          }
          &:nth-child(2){
            top: 0;
            left: 18px;
            width: 0;
            height: 42px;
            transition-delay: .25s;
          }
        }
      }
    }
    &__overlay {
      display: none;
      position: fixed;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.9);
      opacity: 0;
      transition-delay: .625s;
    }
  }
}

.header--show-nav {
  .header__main-nav {
    display: block;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    float: none;
    width: 100%;
    background-color: none;
    &__list {
      position: absolute;
      top: 80px;
      right: 0;
      left: 0;
      bottom: 0;
      float: none;
      margin: 0;
    }
    &__list-item {
      float: none;
      padding: 0;
    }
    &__list-link {
      display: block;
      padding: 20px 40px;
      font-size: 28px;
      text-align: center;
      color: #fff;
    }
  }
}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
  
}

@media (max-width: 800px) {
  .header {
    &__main-nav {
      display: none;
      &__toggle {
        display: block;
      }
    }
  }
  .header--show-nav {
    z-index: 99;
    .header__main-nav {
      display: block;
      &__toggle {
        .burger {
          span {
            width: 0;
            &:nth-child(1){
              transition-delay: 0s;
            }
            &:nth-child(2){
              transition-delay: .125s;
            }
            &:nth-child(3){
              transition-delay: .25s;
            }
          }
        }
        .cross {
          span {
            &:nth-child(1){
              height: 6px;
              transition-delay: .375s;
            }
          }
          span {
            &:nth-child(2){
              width: 6px;
              transition-delay: .625s;
            }
          }    
        }
      }
      &__overlay {
        display: block;
        opacity: 1;
        transition-delay: .625s;
      }
    }
  }
}

@media (max-width: 360px) {
  .header {
    padding: 0 20px;
    &__main-nav {
      &__toggle {
        right: 18px;
      }
    }
  }
}

