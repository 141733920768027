.footer-container {
  background-color: #272727;
}

.mango33-contact {
  margin: 0 auto;
  padding: 80px 0;
  width: 1040px;
  overflow: hidden;
  &__logo {
    display: block;
    margin: 0 0 40px -2px;
    padding: 0;
    width: 188px;
    height: 36px;
    overflow: hidden;
    text-indent: -99em;
    background-size: 188px 36px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url('assets/logo-footer.png');
  }
  &__email {
    float: right;
    margin: 0;
    padding: 0 0 0 40px;
    width: 30%;
    a {
      display: block;
      margin: 4px 0 0 0;
      padding: 0 0 0 50px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 33px;
      font-weight: normal;
      text-decoration: none;
      color: #a8a8a8;
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
      .ico {
        display: block;
        float: left;
        margin: -1px 0 0 -50px;
        width: 40px;
        height: 36px;
        background-size: 40px 36px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-image: url('assets/ico-email.png');
      }
    }
  }
  &__address {
    float: left;
    width: 70%;
    &__list {
      float: left;
      margin: -38px 0 0 -40px;
      padding: 0;
      list-style: none;
      overflow: hidden;
    }
    &__list-item {
      float: left;
      margin: 0;
      padding: 40px 0 0 40px;
      width: 50%;
    }
    &__list-branches {
      font-style: normal;
      &__country {
        margin: 0;
        font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 22px;
        line-height: 1.5;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
      }
      &__location {
        margin: 9px 0 0 0;
        font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 1.5;
        font-weight: normal;
        color: #a8a8a8;
      }
    }
  }
}

.footer {
  clear: both;
  margin: 0;
  padding: 30px 30px 30px 40px;
  height: 86px;
  background-color: #1a1a1a;
  &__nav {
    float: right;
    &__list {
      float: left;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &__list-item {
      float: left;
      margin: 0 20px 0 0;
    }
    &__list-link {
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 26px;
      font-weight: normal;
      text-decoration: none;
      color: #fff;
      &:hover,
      &.active {
        text-decoration: underline;
        color: #fff;
      }
    }
  }
  &__lang-selector {
    float: left;
    padding: 0 0 0 36px;
    font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    .ico {
      display: block;
      float: left;
      margin: 0 0 0 -36px;
      width: 26px;
      height: 26px;
      background-size: 26px 26px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: url('assets/ico-lang-selector.png');
    }
    &__control {
      box-shadow: none !important;
      border-color: #1a1a1a !important;
    }
    &__menu {
      position: absolute !important;
      top: auto !important;
      bottom: 100% !important;
    }
  }
  &__copyright {
    float: left;
    margin: 0;
    font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: normal;
    color: #fff;
    a {
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }
    strong {
      font-weight: normal;
    }
  }
}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
  .mango33-contact {
    &__logo {
      background-image: url('assets/logo-footer@2x.png');
    }
    &__email {
      .ico {
        background-image: url('assets/ico-email@2x.png');
      }
    }
  }
  .footer {
    &__lang-selector {
      .ico {
        background-image: url('assets/ico-lang-selector@2x.png');
      }
    }
  }
}

@media (max-width: 1120px) {
  .mango33-contact {
    width: auto;
    margin: 0 40px;
  }
}

@media (max-width: 960px) {
  .mango33-contact {
    &__email {
      float: none;
      margin-bottom: 40px;
      padding-left: 0;
      width: 100%;
    }
    &__address {
      float: none;
      width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .mango33-contact {
    padding: 40px 0;
  }
  .footer {
    height: auto;
    &__nav {
      margin-bottom: 20px;
      width: 100%;
    }
    &__copyright {
      float: none;
      clear: both;
      width: 100%;
    }
  }
}

@media (max-width: 640px) {
  .mango33-contact {
    overflow: visible;
    &__address {
      &__list {
        float: none;
      }
      &__list-item {
        float: none;
        width: 100%;
      }
    }
  }
}

@media (max-width: 430px) {
  .footer {
    &__nav {
      &__list {
        margin-bottom: 20px;
      }
    }
    &__lang-selector {
      float: none;
      clear: both;
    }
  }
}

@media (max-width: 400px) {
  .footer {
    &__copyright {
      strong {
        display: none;
      }
      span {
        display: block;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 360px) {
  .mango33-contact {
    margin: 0 20px;
  }
  .footer {
    padding-right: 10px;
    padding-left: 20px;
  }
}

