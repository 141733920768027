.about {
  background: #fff;
  &__inner {
    margin: 0 auto;
    padding: 80px 0;
    width: 1040px;
  }
  &__hero {
    position: relative;
    overflow: hidden;
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('assets/hero.jpg');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.4);
    }
    .wrapper {
      position: relative;
      z-index: 3;
      margin: 100px auto;
      width: 600px;
      text-align: center;
    }
    h2 {
      display: inline-block;
      margin: 0 0 40px 0;
      font-size: 35px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 40px;
      text-transform: uppercase;
      color: #fff;
      .separator {
        display: block;
        margin: 10px auto 0 auto;
        width: 100%;
        height: 3px;
        background: #b93526;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        border-radius: 1px;
        -khtml-border-radius: 1px;
      }
    }
    h3 {
      margin: 0;
      font-size: 46px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 58px;
      color: #fff;
    }
    a {
      display: inline-block;
      padding: 12px 40px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background: #b54235;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
      &:hover {
        color: #fff;
        background-color: darken( #b54235, 5% );
      }
    }
  }
  &__detail {
    &__inner {
      margin: 40px 0 0 0;
      &:first-child {
        margin-top: 0;
      }  
    }
    &__header {
      h2 {
        display: inline-block;
        margin: 0 0 2px 0;
        font-size: 22px;
        font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        text-transform: uppercase;
        color: #757575;
        .separator {
          display: block;
          margin: 10px 0 0 0;
          width: 70%;
          height: 3px;
          background: #b93526;
          -moz-border-radius: 1px;
          -webkit-border-radius: 1px;
          border-radius: 1px;
          -khtml-border-radius: 1px;
        }
      }
    }
    &__content {
      p {
        margin: 0;
        padding: 18px 0 0 0;
        font-size: 18px;
        font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        color: #4a4a4a;
      }
    }
  }
}

@media (max-width: 1120px) {
  .about {
    &__inner {
      width: auto;
      margin: 0 40px;
    }
  }
}

@media (max-width: 800px) {
  .about {
    &__inner {
      padding: 40px 0;
    }
  }
}

@media (max-width: 680px) {
  .about {
    &__hero {
      .wrapper {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
  .about {
    &__hero {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 360px) {
  .about {
    &__inner {
      width: auto;
      margin: 0 20px;
    }
  }
}

