.free-trial {
  background: #fff;
  &__inner {
    margin: 0 auto;
    padding: 80px 0;
    width: 1040px;
  }
	&__hero {
    position: relative;
    overflow: hidden;
    .free-trial__inner {
      padding: 40px 0;
    }
    &__title {
      display: inline-block;
      margin: 0 0 40px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
    }
    &__image {
      display: block;
      float: right;
      width: 540px;
    }
    &__download-app-store,
    &__download-play-store {
      display: block;
      margin: 0 0 40px 30px;
      width: 230px;
      height: 70px;
      img {
        display: block;
        width: 100%;
      }
    }
    .wrapper {
      position: relative;
      z-index: 3;
      margin: 0 auto;
      overflow: hidden;
    }
    .wrapper-inner {
      float: left;
      padding: 50px 0 0 0;
    }
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-image: url('assets/bg-hero.jpg');
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
    }
  }
  &__form {
    background: #f6f6f6;
    &__header {
      margin: 0 0 40px 0;
      h2 {
        margin: 0 0 10px 0;
        font-size: 38px;
        font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 48px;
        color: #414141;
      }
      p {
        margin: 0;
        font-size: 22px;
        font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        color: #4a4a4a;
      }
    }
    &__inputs {
      position: relative;
      margin: 0 auto;
      width: 700px;
    }
    &__notice {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      float: right;
      margin: 0;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 20px;
      color: #4a4a4a;
    }
    &__list {
      margin: 0 0 40px 0;
      padding: 10px 0 0 0;
      list-style: none;
    }
    &__list-item {
      width: 100%;
      margin: 0;
      padding: 20px 0 0 0;
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 50%;
      }
      &:nth-child(2),
      &:nth-child(4) {
        float: left;
        padding-right: 20px;
      }
      &:nth-child(3),
      &:nth-child(5) {
        float: right;
        padding-left: 20px;
      }
      &:nth-child(6) {
        clear: both;
      }
    }
    &__label {
      display: block;
      margin: 0 0 0 5px;
      font-size: 14px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-transform: uppercase;
      color: #4a4a4a;
    }
    &__txt {
      display: block;
      padding: 21px 20px 19px 20px;
      width: 100%;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 20px;
      color: #4a4a4a;
      background: #fff;
      border: 1px solid #d6d6d6;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
    }
    &__accept {
      padding: 0 0 40px 0;
      label {
        display: inline-block;
        padding: 0 0 0 34px;
        font-size: 18px;
        font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        color: #4a4a4a;
        cursor: pointer;
        .ico {
          display: block;
          float: left;
          margin: 2px 0 0 -34px;
          width: 24px;
          height: 24px;
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: 0 0;
          background-size: 24px 24px;
          background-image: url('assets/ico-checkbox.png');
        }
        &.checked .ico {
          background-image: url('assets/ico-checkbox-checked.png');
        }
      }
    }
    &__action {
      display: block;
      padding: 12px 40px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background: #b54235;
      border: none;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: darken( #b54235, 5% );
      }
      &--trial {
        float: left;
      }
      &--demo {
        float: right;
      }
      &__wrapper {
        overflow: hidden;
      }
    }
    &__message-wrapper {
      margin: 0 auto;
      padding: 0;
      width: 700px;
    }
    &__message {
      display: none;
      margin: 0 0 40px 0;
      padding: 25px 30px;
      font-size: 14px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      color: #fff;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
    }
    &--error {
      .free-trial__form__message {
        display: block;
        background: #b54235;
      }
    }
    &--success {
      .free-trial__form__message {
        display: block;
        background: darken( #aacb68, 15% );
      }
    }
  }
  
  &__dropdown {
    &__control {
      box-shadow: none !important;
      border-color: #d6d6d6 !important;
    }
  }

  @media
  all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) { 
    &__hero {
      .bg {
        background-image: url('assets/bg-hero@2x.jpg');
      }
    }
    &__form {
      &__accept {
        label {
          .ico {
            background-image: url('assets/ico-checkbox@2x.png');
          }
          &.checked .ico {
            background-image: url('assets/ico-checkbox-checked@2x.png');
          }
        }
      }
    }
  }

  @media (max-width: 1120px) {
    &__inner {
      width: auto;
      margin: 0 40px;
    }
  }

  @media (max-width: 980px) {
    &__hero {
      &__image {
        width: 50%;
      }
      .wrapper-inner {
        width: 50%;
        padding-top: 30px;
      }
    }
  }

  @media (max-width: 800px) {
    &__inner {
      padding: 40px 0;
    }
  }

  @media (max-width: 780px) {
    &__form {
      &__inputs,
      &__message-wrapper {
        width: 100%;
      }
    }
  }

  @media (max-width: 720px) {
    &__hero {
      .free-trial__inner {
        padding-bottom: 0;
      }
      &__title {
        margin-right: -20px;
        margin-left: -20px;
        padding: 5px 20px;
        background-color: #fff;
        text-align: center;
      }
      &__image {
        display: none;
      }
      &__download-app-store,
      &__download-play-store {
        margin: 0 auto 40px auto;
      }
      .wrapper-inner {
        float: none;
        padding-top: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  @media (max-width: 640px) {
    &__form {
      &__list-item {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          float: none;
          width: 100%;
        }
        &:nth-child(2),
        &:nth-child(4) {
          padding-right: 0;
        }
        &:nth-child(3),
        &:nth-child(5) {
          padding-left: 0;
        }
      }
    }
  }

  @media (max-width: 540px) {
    &__form {
      &__action {
        &--trial,
        &--demo {
          float: none;
          width: 100%;
        }
        &--trial {
          margin-bottom: 40px;
        }
      }
    }
  }

  @media (max-width: 420px) {
    &__hero {
      .bg {
        display: none;
      }
    }
  }

  @media (max-width: 390px) {
    &__hero {
      .free-trial__inner {
        margin: 0 20px;
      }
    }
    &__form {
      &__list {
        margin-bottom: 20px;
      }
      &__accept {
        padding-bottom: 20px;
      }
      &__action {
        &--trial {
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 360px) {
    &__inner {
      margin: 0 20px;
    }
    &__hero {
      &__title {
        font-size: 34px;
      }
    }
  }
}

