.terms-of-use {
  background: #fff;
  &__inner {
    margin: 0 auto;
    padding: 80px 0;
    width: 1040px;
  }
  &__title {
    margin: 0 0 40px 0;
    font-size: 38px;
    font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 48px;
    color: #414141;
  }
  &__loader {
    display: block;
    margin: 80px auto;
    width: 48px;
    opacity: 0.7;
  }

  @media (max-width: 1120px) {
    &__inner {
      width: auto;
      margin: 0 40px;
    }
  }

  @media (max-width: 360px) {
    &__inner {
      margin: 0 20px;
    }
  }
}