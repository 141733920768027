.home {
  background: #fff;
  &__inner {
    margin: 0 auto;
    padding: 80px 0;
    width: 1040px;
  }
  &__hero {
    position: relative;
    overflow: hidden;
    .bg {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-image: url('assets/hero.jpg');
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.4);
    }
    .wrapper {
      position: relative;
      z-index: 3;
      margin: 40px auto;
      width: 880px;
      text-align: center;
    }
    h2 {
      display: inline-block;
      margin: 0 0 40px 0;
      font-size: 35px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 40px;
      text-transform: uppercase;
      color: #fff;
      .separator {
        display: block;
        margin: 10px auto 0 auto;
        width: 100%;
        height: 3px;
        background: #b93526;
        -moz-border-radius: 1px;
        -webkit-border-radius: 1px;
        border-radius: 1px;
        -khtml-border-radius: 1px;
      }
    }
    h3 {
      margin: 0 0 60px 0;
      font-size: 46px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 58px;
      color: #fff;
    }
    a {
      display: inline-block;
      padding: 12px 40px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background: #b54235;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
      &:hover {
        color: #fff;
        background-color: darken( #b54235, 5% );
      }
    }
  }
  &__include {
    background-color: #f6f6f6;
    &__header {
      h2 {
        display: inline-block;
        margin: 0 0 40px 0;
        font-size: 22px;
        font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        text-transform: uppercase;
        color: #757575;
        .separator {
          display: block;
          margin: 10px 0 0 0;
          width: 70%;
          height: 3px;
          background: #b93526;
          -moz-border-radius: 1px;
          -webkit-border-radius: 1px;
          border-radius: 1px;
          -khtml-border-radius: 1px;
        }
      }
    }
    &__sub-header {
      margin: 0 0 40px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
    }
    &__list {
      margin: -40px 0 0 0;
      padding: 0;
      overflow: hidden;
      list-style: none;
    }
    &__list-item {
      float: left;
      margin: 0;
      padding: 40px 120px 0 65px;
      width: 50%;
      .ico {
        display: block;
        float: left;
        margin: 0 0 0 -65px;
        width: 45px;
        height: 45px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 45px 45px;
      }
      &:nth-child(odd) {
        clear: left;
      }
      &:nth-child(even) {
        padding-right: 80px;
        padding-left: 105px;
      }
      .ico {
        display: block;
        float: left;
        margin: 0 0 0 -65px;
        width: 45px;
        height: 45px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 45px 45px;
      }
      &__news {
        .ico {
          background-image: url('assets/ico-home-news.png');
        }
      }
      &__events {
        .ico {
          background-image: url('assets/ico-home-events.png');
        }
      }
      &__benefits {
        .ico {
          background-image: url('assets/ico-home-benefits.png');
        }
      }
      &__challenges {
        .ico {
          background-image: url('assets/ico-home-challenges.png');
        }
      }
      &__employee {
        .ico {
          background-image: url('assets/ico-home-employee.png');
        }
      }
      &__social {
        .ico {
          background-image: url('assets/ico-home-social-nnetworking.png');
        }
      }
    }
    &__list-title {
      margin: 0 0 10px 0;
      font-size: 22px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 45px;
      color: #414141;
    }
    &__list-desc {
      margin: 0 0 0 -65px;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 1.5;
      color: #4a4a4a;
    }
  }
  &__benefits {
    &__header {
      h2 {
        display: inline-block;
        margin: 0 0 40px 0;
        font-size: 22px;
        font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        text-transform: uppercase;
        color: #757575;
        .separator {
          display: block;
          margin: 10px 0 0 0;
          width: 70%;
          height: 3px;
          background: #b93526;
          -moz-border-radius: 1px;
          -webkit-border-radius: 1px;
          border-radius: 1px;
          -khtml-border-radius: 1px;
        }
      }
    }
    &__sub-header {
      margin: 0 0 40px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
    }
    &__list {
      margin: -40px 0 0 0;
      padding: 0;
      overflow: hidden;
      list-style: none;
    }
    &__list-item {
      float: left;
      margin: 0;
      padding: 40px 120px 0 40px;
      width: 50%;
      &:nth-child(odd) {
        padding-left: 0;
        clear: left;
      }
      &:nth-child(even) {
        padding-right: 80px;
      }
    }
    &__list-title {
      margin: 0 0 10px 0;
      font-size: 22px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 1.5;
      color: #414141;
    }
    &__list-desc {
      margin: 0;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 1.5;
      color: #4a4a4a;
    }
  }
  &__teaser {
    text-align: center;
    background-color: #f6f6f6;
    h2 {
      margin: 0 0 40px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
      strong {
        font-weight: normal;
        text-transform: uppercase;
      }
    }
    a {
      display: inline-block;
      padding: 12px 40px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background: #b54235;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
      &:hover {
        color: #fff;
        background-color: darken( #b54235, 5% );
      }
    }
  }
}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
  .home {
    &__include {
      &__list-item {
        &__news {
          .ico {
            background-image: url('assets/ico-home-news@2x.png');
          }
        }
        &__events {
          .ico {
            background-image: url('assets/ico-home-events@2x.png');
          }
        }
        &__benefits {
          .ico {
            background-image: url('assets/ico-home-benefits@2x.png');
          }
        }
        &__challenges {
          .ico {
            background-image: url('assets/ico-home-challenges@2x.png');
          }
        }
        &__employee {
          .ico {
            background-image: url('assets/ico-home-employee@2x.png');
          }
        }
        &__social {
          .ico {
            background-image: url('assets/ico-home-social-nnetworking@2x.png');
          }
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .home {
    &__inner {
      width: auto;
      margin: 0 40px;
    }
  }
}

@media (max-width: 960px) {
  .home {
    &__hero {
      .wrapper {
        width: 100%;
      }
    }
    &__include {
      &__list-item {
        padding-right: 40px;
        &:nth-child(even) {
          padding-right: 0;
        }
      }
    }
    &__benefits {
      &__list-item {
        padding-right: 40px;
        &:nth-child(even) {
          padding-right: 0;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .home {
    &__inner {
      padding: 40px 0;
    }
    &__include {
      &__list-item {
        width: 100%;
        padding: 40px 0 0 65px;
        &:nth-child(even) {
          padding-left: 65px;
        }
      }
    }
    &__benefits {
      &__list-item {
        width: 100%;
        padding: 40px 0 0 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .home {
    &__hero {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

@media (max-width: 360px) {
  .home {
    &__inner {
      width: auto;
      margin: 0 20px;
    }
  }
}

