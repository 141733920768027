.pricing {
  background: #fff;
  &__inner {
    margin: 0 auto;
    padding: 80px 0;
    width: 1040px;
  }
  &__detail {
    &__title {
      margin: 0 0 80px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
    }
  }
  &__features {
    &__wrapper {
      margin: 0 auto;
      padding: 0;
      width: 900px;
      .clear {
        display: block;
        clear: both;
      }
    }
    &__box {
      position: relative;
      float: left;
      margin: 0;
      padding: 40px;
      width: 50%;
      text-align: center;
      border: 1px solid #d6d6d6;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    &__title {
      margin: 0 0 40px 0;
      font-size: 22px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 30px;
      text-align: left;
      text-transform: uppercase;
      color: #757575;
    }
    &__sub-title {
      margin: 0 0 10px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 42px;
      text-align: left;
      color: #b54235;
    }
    &__p {
      margin: 0 0 40px 0;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 42px;
      text-align: left;
      color: #4a4a4a;
    }
    &__info {
      margin: 0 0 20px 0;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 24px;
      text-align: left;
      color: #4a4a4a;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &__list-item {
      margin: 20px 0 0 0;
      padding: 0 0 0 44px;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 24px;
      text-align: left;
      color: #4a4a4a;
      &:first-child {
        margin-top: 0;
      }
      .ico {
        display: block;
        float: left;
        margin: 0 0 0 -44px;
        padding: 0;
        width: 24px;
        height: 24px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 24px 24px;
      }
    }
    &__box--std {
      padding-top: 100px;
      -webkit-box-shadow: 0px 10px 20px 3px rgba(155,155,155,0.35);
      -moz-box-shadow: 0px 10px 20px 3px rgba(155,155,155,0.35);
      box-shadow: 0px 10px 20px 3px rgba(155,155,155,0.35);
      .ico {
        background-image: url('assets/ico-checklist.png');
      }
    }
    &__box--custom {
      float: right;
      margin-top: 61px;
      padding-top: 39px;
      min-height: 673px;
      .ico {
        background-image: url('assets/ico-plus.png');
      }
    }
    &__list__type {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 12px 40px;
      width: 100%;  
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;      
      border-bottom: 1px solid #d6d6d6;
      background: #fbb332;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &__list__action-btn {
      display: inline-block;
      margin: 0 0 40px 0;
      padding: 12px 40px;
      font-size: 18px;
      font-family: "sf_ui_displaymedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 2;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      background: #b54235;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      border-radius: 6px;
      -khtml-border-radius: 6px;
      &:hover {
        color: #fff;
        background-color: darken( #b54235, 5% );
      }
    }
  }
  &__faq {
    background-color: #f6f6f6;
    &__title {
      margin: 0 0 40px 0;
      font-size: 38px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 48px;
      color: #414141;
    }
    &__list {
      margin: -40px 0 0 0;
      padding: 0;
      overflow: hidden;
      list-style: none;
    }
    &__list-item {
      float: left;
      margin: 0;
      padding: 40px 40px 0 40px;
      width: 50%;
      &:nth-child(odd) {
        padding-left: 0;
        clear: left;
      }
      &:nth-child(even) {
        padding-right: 0;
      }
    }
    &__list-item__question {
      margin: 0 0 10px 0;
      font-size: 22px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 1.5;
      color: #414141;
    }
    &__list-item__answer {
      margin: 0;
      font-size: 18px;
      font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: normal;
      line-height: 1.5;
      color: #4a4a4a;
    }
  }
}

@media
all and (-webkit-min-device-pixel-ratio : 1.5),
all and (-o-min-device-pixel-ratio: 3/2),
all and (min--moz-device-pixel-ratio: 1.5),
all and (min-device-pixel-ratio: 1.5) {
  .pricing {
    &__features {
      &__box--std {
        .ico {
          background-image: url('assets/ico-checklist@2x.png');
        }
      }
      &__box--custom {
        .ico {
          background-image: url('assets/ico-plus@2x.png');
        }
      }
    }
  } 
}

@media (max-width: 1120px) {
  .pricing {
    &__inner {
      width: auto;
      margin: 0 40px;
    }
  }
}

@media (max-width: 980px) {
  .pricing {
    &__features {
      &__wrapper {
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 950px) {
  .pricing {
    &__features {
      &__box--custom {
        min-height: 703px;
      }
      &__p {
        min-height: 72px;
      }
    }
  }
}

@media (max-width: 915px) {
  .pricing {
    &__features {
      &__box--std {
        min-height: 786px; 
      }
      &__box--custom {
        min-height: 727px;
      }
    }
  }
}

@media (max-width: 800px) {
  .pricing {
    &__inner {
      padding: 40px 0;
    }
    &__detail {
      &__title {
        margin-bottom: 40px;
      }
    }
    &__features {
      &__box {
        float: none;
        width: 100%;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
      }
      &__box--std {
        min-height: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      &__box--custom {
        margin-top: 40px;
        padding-top: 40px;
        min-height: 0;
      }
      &__p {
        min-height: 36px;
      }
    }
    &__faq {
      &__list-item {
        width: 100%;
        padding: 40px 0 0 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .pricing {
    &__detail {
      &__title {
        margin: 0 40px 40px 40px;
      }
      .pricing__inner {
        margin: 0;
      }
    }
    &__features {
      &__box {
        padding-bottom: 0;
        border: none;
        box-shadow: none;
      }
      &__box--std {
        padding-top: 40px;
      }
      &__box--custom {
        margin-top: 60px;
        padding-top: 60px;
      }
      &__title {
        margin-bottom: 20px;
      }
      &__list__type {
        top: 40px;
        right: 40px;
        left: auto;
        padding: 0 12px;
        width: auto;
        font-size: 12px;
        line-height: 30px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
      &__list__separator {
        display: block;
        position: absolute;
        top: -2px;
        left: 50%;
        margin-left: -20%;
        width: 40%;
        height: 4px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        background: #b54235;
      }
    }
  }
}

@media (max-width: 360px) {
  .pricing {
    &__inner {
      margin: 0 20px;
    }
    &__detail {
      &__title {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    &__features {
      &__box {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}

