* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "sf_ui_displaylight", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #545454;
  background: #1a1a1a;
}

a {
  text-decoration: underline;
  color: #545454;
}

a:hover {
  text-decoration: none;
  color: #414141;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hide {
  display: none;
}

.container {
  padding: 80px 0 0 0;
}